.thankyou-page {
    .container-wrapper {
        max-width: 580px;
        width: 100%;

        .container {
            border: none;

            .with-button {
                background-color: #FAFAFA;
                padding: 24px;
                text-align: center;

                .subtext {
                    color: #444444
                }
            }

            .email-div {
                width: 50%;
                text-align: left;
                display: flex;
                justify-content: flex-start;
            }

            .block-wrapper {
                display: flex;
                flex-direction: row;
            }

            .div-wrapper {
                flex-direction: row;
                justify-content: space-between;
                border: none;
                background-color: transparent;
                align-items: center;
            }

            .div-wrapper .refId {
                gap: 8px;
            }

            .details-block {
                background-color: #FAFAFA;
                text-align: left;
                padding: 24px;
                margin-bottom: 24px;

                .item-container {
                    &.orderSummary-container {
                        h4 {
                            font-size: 1.5em;
                            line-height: 2em;
                        }

                        .sub-description {
                            font-size: 1.125em;
                        }
                    }
                }

                .one-column.right {
                    text-align: right;
                }
            }

            .detail-wrapper {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }

            .summary-block {
                padding: 0px 24px;
            }

            .product-detail-wrapper {
                display: flex;
                flex-direction: column;
                gap: 4px;
            }

            .product-name {
                font-size: 18px;
            }

            .subscription-subtext {
                font-size: 14px;
            }

            .payment-method {
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;

                img {
                    width: 48px;
                    height: 28px;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .thankyou-page {
        .container-wrapper {
            min-width: 580px;
        }
    }
    }