
.forgotten-password .container {
    max-width: 480px;
    background-color: #ffffff;
    border: 1px solid #DCDCDC;
    padding: 20px 16px;
}

.back-button{
    border-bottom: none; 
    color:#222222;
    line-height: 22px; 
    display: flex;
}

.back-button:hover{
    cursor:pointer;
}

