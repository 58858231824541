

    .promo-field {
        position: absolute;
    }

    .dropdown {
        color: #1175D2;
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        cursor: pointer;
        background-color: transparent;
        border: none;
        flex-direction: row;
        display: flex;
        

        .upArrow {
            background-image: url(../../UI/Icons/up-arrow.svg);
            display: block;
            width: 12px;
            height: 12px;
            padding: 17px 14px;
            background-repeat: no-repeat;
            background-position: center;
        }
        
        .downArrow {
            background-image: url(../../UI/Icons/down-arrow.svg);
            width: 12px;
            height: 12px;
            padding: 17px 14px;
            background-repeat: no-repeat;
            background-position: center;
        }

    }

