.dropdown-wrapper{

    .div-wrapper{
        display:flex;
        flex-direction: column;

    }

    ul{
        padding-inline-start:0px ;
        li{
            list-style: none;
            cursor:pointer;
            padding:6px 12px;
        }

        li:hover{
           background-color: #f2f2f2;
        }
    }
    
    .search-dropdown{
          max-height: 200px;
         //  position: absolute;
        //  top:85px;
         width:100%;
         background-color: #fafafa;
         overflow-y:scroll;
      }
      
}

  .autocomplete-address{
    color: #787878;
    font-size:15px;
  }

  .highlight{
    font-weight:800;
  }