
.reset-password .container {
    max-width: 480px;
    background-color: #ffffff;
    border: 1px solid #DCDCDC;
    padding: 20px 16px;
}

.back-button{
    border-bottom: none; 
    color:#222222;
    line-height: 22px; 
    display: flex;
    gap:4px;
    padding:5px 0

}

.back-button:hover{
    cursor:pointer;
}

.back-icon {
    display: -webkit-inline-box;
    background-image: url(../../UI/Icons/back-arrow.svg);
    width: 6.06px;
    margin-top: 5px;
    padding: 11px 0 0 13px;
    height: 10px;
    background-repeat: no-repeat;
    background-position: left;
}