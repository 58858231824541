.success-box {
    border-left: 2px solid #1B8849;
    display: flex;
    align-items: center;
    padding-left: 8px;
    height:28px;
}
    .success-box span.success-icon {
        background-image: url(../Icons/success-tick-icon.svg);
        width: 12px;
        display: block;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 8px;
    }
.success-box span {
    color: #1B8849;
    font-size:14px;
}

