$font: 'PT-Sans', sans-serif;


.promo-code-container {
    position: absolute;
    bottom: 0%;
    margin-bottom: 30px;
    width: 100%
}

.theme1-promo-container,
.theme2-promo-container,
.theme3-promo-container,
.theme4-promo-container,
.theme5-promo-container,
.theme6-promo-container {
    h1 {
        font-family: $font;
        font-style: normal;
        font-weight: 700;
        font-size: 46px;
        line-height: 56px;
        color: #444444;

    }

    h2 {
        font-family: $font;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #444444;
        flex: none;
        order: 1;
        flex-grow: 0;

    }

    p {

        font-family: $font;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        font-size: 16px;
        color: #222222;

    }

    h3 {
        font-family: $font;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #444444;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

    }




}

.theme1-promo-container,
.theme2-promo-container,
.theme3-promo-container {

    img {
        object-fit: cover;
        max-width: 100%;
        display: flex;
        width: 50%;
    }

    ul {
        padding-inline-start: 0px;
    }

    display:flex;
    flex-direction: row;
    justify-content: center;


}


.theme1-promo-container {


    gap: 20px;
    position: relative;
    align-items: flex-start;

    /*change to absolute*/

    li {
        display: flex;
        gap: 10px;
        list-style: none;

        .checked {
            padding: 12px 10px;
        }
    }
}

.theme2-promo-container {

    gap: 20px;

    &>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }



    .blue-circle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        grid-gap: 8px;
        gap: 8px;
        width: 48px;
        height: 48px;
        background: #1175D2;
        border-radius: 33px;

    }

    .info-icon {
        background-image: url(../../UI/Icons/info-icon-24.svg);
        width: 24px;
        display: block;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;

    }

    .calendar-icon {
        background-image: url(../../UI/Icons/calendar-icon.svg);
        width: 24px;
        display: block;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;

    }

    li {
        display: flex;
        gap: 20px;
        list-style: none;

    }
}

.theme3-promo-container {
    display: flex;

    gap: 40px;

    .promo-div-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;

        gap: 24px;
        background: #FAFAFA;

    }


}

.theme4-promo-container {

    flex-direction: column;
    justify-content: center;
    align-items: center;

    .promo-image {
        width: 100%;
        display: block;
        background-size: cover;
        background-image: url(./PromoImages/PromoImage3.svg);
        padding: 69px 253px;
    }

    .promo-div-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        padding: 40px;
        background: #FAFAFA;

    }

    p {
        text-align: center;
    }

    h3 {
        text-align: center;
    }

}

.theme5-promo-container {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;



    img {
        object-fit: cover;
        height: 200px;
    }

    &>div {
        justify-content: center;
        text-align: center;
        padding: 24px 50px;
    }

    h1 {
        position: absolute;
        color: #222222;

        left: 37%;
        top: 18%
    }

    .list {
        display: flex;
        flex-direction: row;
        justify-content: center;

    }

    ul {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    li {
        list-style: none;
        display: flex;
        justify-content: center;
        gap: 10px;


        .checked {
            padding: 12px 10px;
        }
    }

}

.theme6-promo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;




    &>div {
        justify-content: center;
        text-align: center;
        padding: 24px 50px;
    }

    h1 {

        color: #222222;
        left: 37%;
        top: 18%
    }

    .list {
        display: flex;
        flex-direction: row;
        justify-content: center;

    }

    ul {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    li {
        list-style: none;
        display: flex;
        justify-content: center;
        gap: 10px;


        .checked {
            padding: 12px 10px;
        }
    }

}

//for mobile screens
@media screen and (max-width:781px) {
    .page-container {
        width: auto;
    }

    .theme1-promo-container,
    .theme2-promo-container,
    .theme3-promo-container,
    .theme5-promo-container,
    .theme6-promo-container {
        display: flex;
        flex-direction: column;
        position: relative;

        img {

            width: 100%;
        }
    }

    .promo-code-container {
        position: relative;
    }

    .theme5-promo-container h1 {
        position: absolute;
        color: #222222;
        left: 14%;
        top: 7%;
    }

    .theme4-promo-container .promo-image {
        width: 100%;
        display: block;
        background-size: cover;
        background-image: url(./PromoImages/PromoImage3.svg);
        padding: 69px 45.3px;
    }
}