

.modal {
    display: none;

    &.open {
        display: block;
        /* Add additional styling for positioning and appearance */
    }

    &.container {
        position: fixed;
        z-index: 9999;
        top: 0;
        height: 100vh;
        width: 100%;
        margin: 0;
        background-color: white;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
    }

    .modal-content {
        .container {
            padding: 18px 16px 0 16px;
        }
    }

    .close-button {
        position: absolute;
        right: 8px;
        top: 24px;
        border: 0;
        background-color: transparent;
    }
}

//modal with basket on mobile
.modal {
    h2.module-heading {
        width: fit-content;
    }

    .back-button {
        margin-left: auto;
    }

    .container-header {

        &:after {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: #dcdcdc;
        }

        .container {
            .item-wrapper {
                width: fit-content;
            }
        }
    }

    .container {
        &.summary {
            position:relative;
            .block-container {
                background-color: #fafafa;
                padding: 24px 32px 32px;
                width: 100%;
                height: -moz-fit-content;
                height: 100%;
                width: calc(100% + 64px);
                margin-left: -32px;
                font-size: 14px;
            }
        }
    }
}


.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
}
