/* Provide sufficient contrast against white background */

html {
    font-size: 16px;
}

body {
    background-color: #FaFaFa;
    font-family: 'PT Sans', Arial, sans-serif;
}

* {
    box-sizing: border-box;
}

h1 {
    font-size: 1.75rem;
    line-height: 32px;
}

h2 {
    font-size: 1.375rem;
    line-height: 28px;
}

h3 {
    font-size: 1.25rem;
    line-height: 24px;
}

h4 {
    font-size: 1.125rem;
    line-height: 24px;
}

h5 {
    font-size: 1rem;
    line-height: 24px;
}

a {
    color: #1175D2;
    text-decoration: none;
}

a:hover {

    text-decoration: underline;
}

p {
    margin: 0;

    small {
        font-size: 0.875rem;
    }

    &.large { 
            font-size: 1.125em;
            line-height: 1.6em; 
    }
}

ul {
    line-height: 24px;
    margin: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    padding-inline-start: 20px;
}

header {
    clear: both;
}

code {
    color: #E01A76;
}

section {
    margin-bottom: 48px;
    position: relative;
}

.loader-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
}

.loader {
    border: 2px solid #1175D2;
    border-top: 2px solid #e3e3e3;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.wrapper-content {
    flex: 1;
    display: flex;
    margin: 48px 0;
    align-items: center;
    flex-direction: column;
}

.container-wrapper {
    max-width: 600px;
    width: 100%;

    &.one-column {
        max-width: 480px;
        .container {
            padding: 20px;
        }
    }

    &.two-columns {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }



    .container {
        background-color: #fff;
        border: 1px solid #DCDCDC;
        padding: 20px 10px;
        width: 100%;
        height: fit-content;
        margin-top: -1px;
       
        &.summary {
            padding-bottom: 0;

            .back-button {
               
                font-size: 14px;
               
            }

            .block-container {
                background-color: #fafafa;
                padding: 24px 32px 32px;
                width: 100%;
                height: fit-content;
                width: calc(100% + 64px);
                margin-left: -32px;
                font-size: 14px;
            }

            @media only screen and (max-width: 768px) {
                .block-container {
                    background-color: #fafafa;
                    padding: 24px 32px 32px;
                    width: 100%;
                    height: fit-content;
                    font-size: 14px;
                    border-bottom: 1px solid grey;
                }
            }
        }
    }
}

    .item-wrapper.one-row {
        flex-direction: row;
        gap: 24px;
    }

    .item-field.three-columns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .item-separator {
        width: 100%;
        height: 1px;
        background-color: #dcdcdc;
    }
   
    /*Block header*/
.block-header {
    font-size: 1.7rem;
    line-height: 32px; 
}
    /* Module Typography */
    .meta-info{
        font-weight:normal;
        color:#707070;
    }
    .module-heading {
        font-size: 1.5rem;
        line-height: 32px;
    }

    .module-description {
        font-size: 0.875rem;
        line-height: 24px;
    }

    .module-quantity-block {
        gap: 4px;
        font-size: 16px;
        line-height: 24px;
    }

    .module-price {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        line-height: 28px;
    }
    /*  meta */
    .meta {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
    }

    .meta p {
        line-height: 20px;
    }

    .btn-primary {
        color: #fff;
        background-color: #1b6ec2;
        border-color: #1861ac;
    }

    .text-between-border {
        font-size: 0.875rem;
        line-height: 1.5rem;
        font-weight: 700;
        text-align: center;
        text-transform: capitalize;
        position: relative;
        color: #707070;
    }

    .text-between-border:after,
    .text-between-border:before {
        content: '';
        position: absolute;
        width: calc(50% - 16px);
        height: 1px;
        background-color: #DCDCDC;
        display: block;
        top: 12px;
    }

    .text-between-border:before {
        left: 0;
    }

    .text-between-border:after {
        right: 0;
    }

    .border-seperator {
        width: 100%;
        height: 1px;
        background-color: #DCDCDC;
        display: block;
    }

    .two-columns-row {
        display: flex;
        justify-content: space-between;

        span {
            line-height: 24px;
            font-size: 14px;

            &.order-price {
                font-size: 16px;
            }
        }
    }

.module-column {
    //max-width: 250px;
    margin-right: 30px;
}

    .module-block {
        display: inline;


        .module-subheading,
        .module-quantity-block {
            display: inline;
        }

        .module-subheading {
            font-size: 1.125rem;
            line-height: 24px;
        }

        .module-description {
            font-size: 0.875rem;
            line-height: 20px;
        }

        .module-quantity-block {
            gap: 4px;
            font-size: 16px;
            line-height: 24px;
        }
    }


    .customScroll {
        overflow-x: auto;
        padding-right: 16px;


        &::-webkit-scrollbar {
            width: .2vw;
            margin-left: 10px;
        }


        &::-webkit-scrollbar-thumb {
            background-color: #707070;
        }

        &::-webkit-scrollbar-track {
            background-color: #DCDCDC;
        }

        &.customScrollerHeight {
            max-height: 136px;
        }
    }


    .div-language-selector {
    }

    .img-language-selected {
        border-color: #000000;
        border-width: thin;
        border-style: solid;
    }

    .errorBox {
        border-width: medium;
        border-style: solid;
        border-block-color: red;
        background-color: #F0AABA;
    }


    .left-column {
        background-color: #ffffff;
        float: left;
        padding: 10px;
        margin-left: 200px;
        width: 50%;
    }

    .center-column {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 40px 0;
    }

    .right-column {
        background-color: #ffffff;
        padding: 10px;
        float: right;
        margin-right: 200px;
    }

    table.basket {
        width: 100%;
    }

    td.basket-label {
        padding: 5px;
    }

    td.basket-value {
        padding: 5px;
        text-align: right;
    }

    div.auth-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    div.auth-left {
        flex: 50%;
    }

    div.auth-content {
        width: 100%;
    }

    div-auth-right {
        flex: 50%;
    }

    div.url-warning-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }

    div.url-warning-left {
        content: '';
        min-width: 38px;
        min-height: 38px;
        background-image: url('./css/warning-signs-svgrepo-com.svg');
        flex: 10%;
        background-size: contain;
        background-position-x: center;
        background-repeat: no-repeat;
    }

    div.url-warning-center {
        flex: 80%;
    }

    div.url-warning-right {
        flex: 10%;
        content: '';
        min-width: 38px;
        min-height: 38px;
        background-image: url('./css/warning-signs-svgrepo-com.svg');
        background-size: contain;
        background-position-x: center;
        background-repeat: no-repeat;
    }

    span.password-rule-fail {
        color: #DD2B1B;
    }

    span.password-rule-pass {
        color: green;
    }

    .noshow {
        display: none;
    }


.payment-process .container {
    max-width: 580px;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #DCDCDC;
    padding: 20px 16px;
    text-align: center;
 

    .loader-wrapper {
        position:relative;
    }
    }
    @media only screen and (min-width: 768px) {

        .block-header {
            font-size: 1.5rem;
            line-height: 32px;
        }

        h1 {
            font-size: 3rem;
            line-height: 56px;
        }

        h2 {
            font-size: 1.5rem;
            line-height: 32px;
        }

        h3 {
            font-size: 1.375rem;
            line-height: 28px;
        }
        // .container-wrapper {
        //     padding:0 20px;
        //         &.two-columns {
        //             grid-template-columns: 1.5fr 1fr;
        //         }
        //     .container {
        //         padding:32px;
        //         &.login,
        //         &.forgotten-password,
        //         &.register,
        //         &.update-email,
        //         &.update-password {
        //             padding: 32px;
        //         }
        //         }
        //     }
    }

    @media only screen and (min-width: 1024px) {
        .block-header {
            font-size: 2rem;
            line-height: 40px;
        }

        h1 {
            font-size: 2.875rem;
            line-height: 56px;
        }

        h2 {
            font-size: 2rem;
            line-height: 40px;
        }

        h3 {
            font-size: 1.5rem;
            line-height: 32px;
        }

        h4 {
            font-size: 1.25rem;
            line-height: 28px;
        }

        h5 {
            font-size: 1.125rem;
            line-height: 24px;
        }

        .container-wrapper {
            padding: 0 20px;
            max-width: 980px;

                &.two-columns {
                    grid-template-columns: 1.5fr 1fr;
                }

                .container {
                    padding: 32px;

                    &.login,
                    &.forgotten-password,
                    &.register,
                    &.update-email,
                    &.update-password {
                        padding: 32px;
                    }

                    &.block-title {
                        padding: 16px 32px;
                    }
                }
            }
        }

    @media only screen and (min-width: 1220px) {
        .container-wrapper {
            padding: 0;
        }
    }
    //for mobile
    @media only screen and (max-width: 768px) {

        .wrapper-content {
            flex: 1;
            display: flex;
            margin: 0px;
            align-items: center;
            flex-direction: column;
        }
    }