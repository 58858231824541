 .recipientSelectionBlock {
    background-color: #fff;
    border: 1px solid #DCDCDC;
    padding: 20px 10px;
    width: 100%;
 
    height: 100%;
    margin-top: -1px;
    padding: 32px;
    max-width: 480px;

}

.module-row {
    display: flex;
    flex-direction: row;

 .module-pricing {
        font-size: 20px;
        line-height: 24px;
    }

    .price {
        margin-top: 4px;

    }
}

.header-row {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    button {
        background-color: transparent;
        border: none;
        display: flex;
        flex-direction: row;
        gap: 6px;
    }

    .close-icon2 {

        display: -webkit-inline-box;
        background-image: url(../UI/Icons/close-icon2.svg);
        width: 15px;
        margin-top: 1px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: left;


    }
}

.product-offer{
   
    .whoisitfor-block{
        position:absolute;
        right:0;
        top:14%;
        height:81%;
    }
}