:root {
    --sjs-general-backcolor: transparent;
    --sjs-shadow-small: none;
    --sjs-shadow-medium: none;
    --sd-base-padding: 0;
    --sjs-editorpanel-cornerRadius: 0px;
    --sjs-questionpanel-cornerRadius: 0px;
    --sjs-general-backcolor-dim: rgba(255, 255, 255, 1); /*remove panel*/
    --sjs-secondary-backcolor: rgba(255, 152, 20, 1);
    --sjs-secondary-backcolor-light: rgba(255, 152, 20, 0.1);
    --sjs-secondary-backcolor-semi-light: rgba(255, 152, 20, 0.25);
    --sjs-secondary-forecolor: rgba(255, 255, 255, 1);
    --sjs-secondary-forecolor-light: rgba(255, 255, 255, 0.25);
    --sjs-primary-backcolor: #1175d2;
    --sjs-primary-backcolor-dark: rgba(21, 119, 209, 1);
    --sjs-primary-backcolor-light: rgba(37, 137, 229, 0.1);
    --sjs-primary-forecolor: rgba(255, 255, 255, 1);
    --sjs-primary-forecolor-light: rgba(255, 255, 255, 0.25);
    /* remove shadow on input*/
    --sjs-shadow-inner: none !important;
    --sjs-editor-border: 1px solid #ddd !important;
    --sjs-font-family: PT Sans, Arial, sans-serif; /* Change to your preferred font */
    --sjs-shadow-small-reset: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
    --sjs-shadow-medium: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    --sjs-shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
    --sjs-shadow-inner-reset: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
    --sjs-border-inside: rgba(0, 0, 0, 0.16);
    --sjs-corner-radius: 0px;
    --sd-base-vertical-padding:24px;
}
 

.sd-root-modern--mobile .sd-container-modern {
    --sd-base-padding: 0;
}

input.sd-input[type=tel] {
    height:48px;
}
input.sd-input.sd-text {
    border: 1px solid #ddd;
    padding: 0 12px;

    &:focus {
        box-shadow: inset 0px 0px 0px 2px #1175D2;
        border:0;
    }

    &.sd-input--error {
        border: 1px solid #DD2B1B;
        background-color: #FFF4F4;

        &:focus {
            border: 0;
            box-shadow: inset 0px 0px 0px 2px #1175D2;
        }
    }
}
div.sd-input {
    border: 1px solid #ddd;
    padding: 12px 0;

    input[type=number], input[type=email], input[type=text], input[type=password], input[type=date] {
        height: auto;
    }



    &.sd-input--error {
        border: 1px solid #DD2B1B;
        background-color: #FFF4F4;
    }
}


div.sd-input.sd-dropdown:focus-within {
    
        box-shadow: inset 0px 0px 0px 2px #1175D2;
        border:0;
   

    &.sd-input--error {
        border: 0 solid;

        &:focus {
            box-shadow: inset 0px 0px 0px 2px #1175D2;
        }
    }
}
div.sd-item {
    &.sd-radio {
        padding: 0;
        padding-bottom: 18px;

        &:last-child {
            padding-bottom: 0;
        }
    }
}
.sd-item__decorator {
    border: 1px solid #ddd;
}
.sd-item__decorator {
    &.sd-checkbox__decorator{
        border: 1px solid #707070;
    }
}
.sd-checkbox--checked {
    .sd-item__decorator {
        &.sd-checkbox__decorator {
            border: 0;
            padding:6px;
        }
    }
}

.sd-checkbox--checked .sd-item__control:focus + .sd-item__decorator.sd-checkbox__decorator {
    background: #1175d2;
}
.sd-checkbox--checked .sd-checkbox__control:focus + .sd-checkbox__decorator .sd-checkbox__svg use{
    fill:#fff;
}
.sd-radio--checked .sd-item__decorator {
    border: 1px solid #1175d2;
}

div.sd-item--error .sd-item__decorator {
    border: 1px solid #DD2B1B;
    background-color: #FFF4F4;
}


    .sd-dropdown_chevron-button {
        background-image: url(../../UI/Icons/up-arrow-black.svg);
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(180deg);

        svg {
            display: none;
        }
    }

    .sd-dropdown__value {
        padding: 0 12px;
    }

    .sd-dropdown_clean-button {
        display: none;
    }

div.sd-radio--checked .sd-item__decorator {
    background-color: #fff;
}

    div.sd-radio--checked .sd-radio__decorator:after {
        content: " ";
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #1175d2;
    }

    label .sd-item__control:focus + .sd-item__decorator {
        box-shadow: unset;
    }

    div.sd-question__header--location-top {
        padding-bottom: 8px;
    }

    div.sd-root-modern--mobile .sd-body.sd-body--responsive,
    div.sd-root-modern--mobile .sd-body.sd-body--static {
        padding-top: 0;
        padding-bottom: 0;
    }

    div.sd-root-modern.sd-root-modern--full-container {
        width: calc(100% + 46px);
        height: 100%;
        overflow: hidden;
        position: relative;
        margin-left: -24px;
        padding-top: 0;
    }

    div.sd-row.sd-page__row:not(.sd-row--compact) ~ .sd-row.sd-page__row:not(.sd-row--compact) {
        margin-top: 0;
    }

    div.sd-row.sd-page__row {
        margin-top: 0;
    }

    .sd-element--with-frame.sd-question--title-top {
        padding-top: 0;
        margin-bottom: 24px;
    }

    div.sd-input.sd-dropdown:not(.sd-dropdown--empty) {
        border: 1px solid #1175d2;
        &:focus{
            border:0;
        }
    }

    span.sd-question__required-text {
        display: none;
    }

.sd-element--with-frame > div.sd-element__erbox {
    padding-left: 40px;
    margin-right: 0;
    width: calc(100% + 2* var(--sd-base-padding));
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    border-left: 2px solid #DD2B1B;
    margin-top: 8px;
    position: relative;
    color:#DD2B1B;
    > div {
        &:before {
            content: "";
            width: 24px;
            height: 24px;
            position: absolute;
            left: 8px;
            background-image: url(../../UI/Icons/error-icon.svg);
            background-position: center;
            background-repeat: no-repeat;
            top: 4px;
        }
    }
}

.sd-question__comment-area {
    textarea.sd-comment {
        border: 1px solid #ddd;
        height: 48px !important;
        min-height: unset !important;
        resize: none !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        width: 100% !important;
        display: inline-block !important;
        padding: 12px !important;
    }
}
.sd-question--error {
    .sd-question__comment-area {
        textarea.sd-comment {
            border: 1px solid #DD2B1B;
            background-color: #FFF4F4;
            &:focus{
                border:0;
            }
        }
    }
}

textarea.sd-comment {
    border: 1px solid #ddd;

    &.sd-input--error {
        border: 1px solid #DD2B1B;
        background-color: #FFF4F4;

        &:focus {
            border: 0;
        }
    }
}