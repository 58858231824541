 
.hide-btn {
  border: none;
  background-color: var(--bg-transparent);
} 
 
/*--------------------input type=[radio && checkbox] and such components css*/
.radio-flex-div {
  display: flex;
  flex-direction: column;
}

.radio-border {
  border: 1px solid gainsboro;
  width: 280px;
  height: 48px;
  position: relative;
}

.radio-Noborder {
  width: 280px;
  padding: 0px 10px;
}


.radio-div>label {
  margin: 0px 0px 0px 12px;
  font-family: var(--font);
  font-style: normal;
  font-size: 16px;
  padding: 20px 0px 0px 0px;
}

.radio-div {
  display: flex;
}

label.checkbox-label{
    display:flex;

}
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  width: 24px;
  padding: 3px;
   border: 1px solid gainsboro !important;
}

input[type=checkbox]:not(.sd-checkbox__control) {
    width: 24px;
    -webkit-appearance: none;
    background-color: #FFFFFF;
    border-radius: 0;
    flex: 1 0 auto;
}
input[type='checkbox']:not(.sd-checkbox__control) {
    /* remove browser chrome */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    /*add styling */
    position: relative;
    width: 24px;
    border: 1px solid #707070;
    border-radius: 0;
    transition: background-color .25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
input[type='checkbox']:checked:not(.sd-checkbox__control) {
    background-color: #1175D2;
    border: 1px solid #1175D2;
}
input[type='checkbox']:focus-visible:not(.sd-checkbox__control) {
    outline: -webkit-focus-ring-color auto 1px;
}
input[type='checkbox']:not(.sd-checkbox__control)::before {
    content: '';
    color: #fff;
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    background-color: transparent;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 1px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform 0.25s ease-in-out;
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    background-image: url("../Icons/check.svg");
}

    input[type='checkbox']:checked::before {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
.description {
  padding: 0px 10px 0px 42px;
  font-family: var(--font);
  font-style: normal;
  line-height: 20px;

}

.altText {
  position: absolute;
  top: 12px;
  right: 15px;
  font-weight: 700;
  font-family: var(--font)
}


/*---(subpart)--checkbox_input_amount-------*/

.checkbox_input_for_amount {
  border: 1px solid #707070;
  padding: 11px 20px 6px 16px;
  width: 180px;
  height: 64px;
}
/*--------------------input type="text" and such components css--------------------------*/




/*input:placeholder-shown{
  border: 1px solid #ccc;
  border: 1px solid #DCDCDC;
}*/

/*------------------focused-state-------------*/


/*------------------------error state--------------------*/
input.Invalid-input {
  background-color: #FFF9F9 !important;
  border: 1px solid #DD2B1B !important;
  color: black !important;
}

input.Invalid-input ::placeholder {
  color: black;
}

/*--------------------filled-state-----------------*/

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color:var(--bg-transparent);
  border:1px solid var(--bg-transparent);
  color: fieldtext !important;
}

input.filled{

  border:1px solid var(--blue) !important;
}




/* .checkmark{
  height: 24px;
  background-color: white;
  display: block;
  width: 24px;
  border: 1px solid #cbc9c9;
} */


.text-div {
  position: relative;
  
  width:100%;
}

.text-div > .small{
max-width:172px;
}

.text-div > .medium{
  max-width:344px;
  }
  

.help-text-icon {
  font-size: 20px;

}

.optional-text {
  font-weight: 500;
  font-family: var(--font);
}

.help-text-btn {
  border-radius: 100px;
  border: none;
  padding: 10px;
  background-color: #ffffff;
}

.help-text-div {
  border-left: 2px solid #1175D2;
}

.help-text-question {
  font-family: var(--font);
  margin-bottom: 0px;
  Line-height: 20px;
  font-style: normal;
}

.help-text-answer {
  font-family: var(--font);
  Line-height: 20px;
  font-style: normal;
  margin-bottom: 0px;

}


.close-icon {
  display: -webkit-inline-box;
  background-image: url(../Icons/close-icon.svg);
  /* width: 5.58px; */
  width: 10px;
  padding: 10px;
  margin-top: 3px; 
  height: 10px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.question-icon {
  display: -webkit-inline-box;
  background-image: url(../Icons/question-icon.svg);
  /* width: 5.58px; */
  width: 10px;
    padding: 10px;
     margin-top: 3px; 
    height: 10px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}