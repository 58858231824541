
//chrome
@media screen and (-webkit-min-device-pixel-ratio:0){
    .payment-icon {
        display: flex;
        flex-direction: row;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0px 4px 0px 0px
    }

   
}

//safaru
@media not all and (min-resolution: 0.001dpcm) {
    .payment-icon{
        display: flex;
        flex-direction: row;
        background-repeat: no-repeat;
        max-width:39px;
        background-position: center;
        padding: 0px 4px 0px 0px
    }
    }

// .visa {

//     height: 26px;
// }

.module-heading.disabled{
    color:  #A7A7A7
}

.loading-state{
    opacity:0.5
}

.summary{
    top:0;
    position:sticky;
}

// .mastercard,
// .maestro,
// .amex,
// .solowithavscv,
// .dinersclub,
// .switchcard,
// .discover,
// .directdebit,
// .paypal {
//  height: 26px;
// }


.payment-wrapper-mobile {

    .div-wrapper {
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
    }

    .payment-icon-div {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        max-width:250px;
        }

    .payment-icon {
        display: flex;
    }

    .payment-icon-animation{
        position: relative;
        width:40px;
        overflow: hidden;
      }

      .payment-icon-animated{
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;

     
      /* Transition for image opacity */
      }
   
      .show {
        opacity: 1;
        transition: opacity 0.4s ease;
      }

      .hidden{
        opacity:0;
        transition: opacity 0.4s ease;
      }
}

.payment-wrapper {

    .field-wrapper {
        width: 50%;
    }

    .div-wrapper {
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
        flex: 1 1 100%;
    }

    .payment-icon {
        img {
            width: 100%;
        }
    }

    .payment-icon-div {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        max-width: 250px;
        justify-content: flex-end;

        .payment-icon-animation {
            position: relative;
            width: 40px;
            overflow: hidden;
        }

        .payment-icon-animated {
            position: absolute;
            display: flex;
            left: 0;
            width: 100%;
            height: 100%;
            /* Transition for image opacity */
        }

        .show {
            opacity: 1;
            transition: opacity 0.4s ease;
        }

        .hidden {
            opacity: 0;
            transition: opacity 0.4s ease;
        }
    }
}


.cardDetails-row {
    display: flex;
    flex-direction: row;
    gap: 20px;

    #div-sortcode { 
        flex-grow: 0.83;
    }

    #expiryDate {
        flex-grow: 1;

    }

    #cvv {
        flex-grow: 0.8;
    }

    #postcode {
        flex-grow: 0.8;
    }



    #cardnumber {
        flex-grow: 2;
    }
}

.address-search-box{
    display: flex;
    flex-direction: column;
    gap:8px;
}

.user-div {
    background-color: #FAFAFA;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 12px;


    p {
        width: 100%
    }

    button {
        border: 0 solid;
        background-color: transparent;
        line-height: 24px;
        cursor: pointer;
        color: #222;
        font-size: 16px;
    }
}

.item-for-screen-reader{
    display:hidden;
}


.address-styled-list {
    list-style: none;
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
    z-index:10;
    padding-inline-start: 0px;
    box-shadow:inset 0px 0px 0px 1px #1175D2;
    clip-path: inset(0px 0px 0px 0px);
    border-left: 1px solid #1175D2;
    border-right: 1px solid #1175D2;
    border-bottom: 1px solid #1175D2;

   

    a{
        color:inherit;
        text-decoration: none;
    }

    li {
        z-index:0;
        line-height: 24px;
        padding:6px 10px;
        margin:2px 2px;
    }
  
    li:hover {
        background-color: #f2f2f2;
        cursor: pointer;
      }
    
    .selected{
        background-color: #f2f2f2;
    }
  
    li:focus {
        background-color: #f2f2f2;
      }

}

.delivery-address-block {
    position: relative;

    .detail-wrapper {
        padding: 24px;
        display: flex;
        flex-direction: column;
    }
  

    button {
        right: 0px;
        top: -50px;
        position: absolute;
        border: 0 solid;
        background-color: transparent;
        line-height: 24px;
        cursor: pointer;
        color: #222;
        font-size: 16px;
    }

    button:hover {
       text-decoration: underline;
    }
}

.gift-user{

    display: flex;
    flex-direction:column;
    
    .gift-user-row{
        display:flex;
        flex-direction:row;
        gap:20px;
    }

    .auth-row{
       margin-bottom: 20px;
    }
    
}

.guest-user{
    display: flex;
    flex-direction:column;
    
    .guest-user-block{
        position: relative;
        width:100%;
        padding: 20px 0px 0px 0px;
    }
    
    .edit-guest-details{
        position: absolute;
        top:-10px;
        right:0;
        background-color: transparent;
        border: none ;
        cursor:pointer;
    }
       
    
    .guest-user-details{
        background-color: #FAFAFA;
        display:flex;
        gap:8px;
        padding:12px;
        display:flex;
        flex-direction:column;
        width:100%;
    }
    
    .guest-user-row{
        display:flex;
        flex-direction:row;
        gap:20px;
    }
    
    }


//Paypal box styling
.paypal p {
    text-align:center;
    width:100%;
}
.braintree-sheet{
    height:200px;
    max-height:200px;
}
.braintree-placeholder {
    display: none;

}
#dropin-container {
    min-height: 160px;
}

#stripe-dropin-container {
    min-height: 324px;
    // max-height: 329px;
}

.braintree-paypal.braintree-sheet {
    border: 0;
    border-bottom: 1px solid #dcdcdc;
}
.braintree-sheet__header{
    border:0;
    padding:0
}
.braintree-sheet__header .braintree-sheet__header-label {
    display: none;
}
.braintree-sheet__content--button {
    min-height: 56px;
    padding: 0
}
.braintree-sheet--has-error .braintree-sheet__error {
    background-color: #fafafa;
}

#wpcontainer{
background-color: #fafafa;
}

#wp-cl{
max-width: 480px;
}


.pca .pcaautocomplete {
    margin: 0;
    padding: 0;
    border-left: 3px solid #1175D2;
    border-right: 3px solid #1175D2;
    border-top: 1px solid #1175D2;
    border-bottom: 0;
    background: 0;
    box-shadow: none; // loqate dropdown within  the parent width;
    width: auto;
    position: absolute;
    z-index: 9999999999;
}

.pca .pcaautocomplete .pcalist {
    height: 244px;
    width: auto;
    border-bottom: 3px solid #1175D2;
}

.pca{
    width:500px;
}
button.primary-button.disabled.small{
    width:fit-content;
}

@media only screen and (min-width: 520px) {

    .pca .pcaautocomplete {
        width: inherit;
    }
}