.detail-wrapper {
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;

    p {
        width: 100%;
        white-space: pre-line;
    }
 
     
    button{
    border: 0 solid;
    background-color: transparent;
    line-height: 24px;
    cursor: pointer;
    color: #222;
    font-size: 16px;
  
   
    }
    
    button:hover{
        text-decoration:underline;
    }
}

.guest-user{
display: flex;

.guest-user-block{
    position: relative;
    width:100%;
    padding: 20px 0px 0px 0px;
}

.edit-guest-details{
    position: absolute;
    top:-10px;
    right:0;
    background-color: transparent;
    border: none ;
    cursor:pointer;
}
   

.guest-user-details{
    background-color: #FAFAFA;
    display:flex;
    gap:8px;
    padding:12px;
    display:flex;
    flex-direction:column;
    width:100%;
}

.guest-user-row{
    display:flex;
    flex-direction:row;
    gap:20px;
}

}