.spliced-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.options-container {
    padding: 32px 0px;
    border-top: 1px solid #D7D7D7;
    border-bottom: 1px solid #D7D7D7;

    h3 {
        text-align: center;
    }


    .wrap {
        display: flex;
        flex-wrap: wrap;
    }

    .justify-center {
        justify-content: center;
    }

    .justify-space-between {
        justify-content: space-between;
    }

    .options-card-container {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .card {
            display: flex;
            flex-direction: row;
            max-width: 390px;
            padding: 16px;
            gap: 16px;
            height: auto;
            border: 1px solid #F2F2F2;
            background-color: #FaFAFA;


        }

        .card:hover{
            background-color: #D3D3D3;
            cursor:pointer;
        }

        .icon {
            display: flex;
            flex-direction: row;
            align-items: center;

        }

        .right-arrow {
            background-image: url(../../UI/Icons/right-arrow.svg);
            width: 12px;
            height: 12px;
            padding: 11px 14px 11px 22px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

}


@media screen and (max-width:648px) {

    .options-container {
        margin: 0px;

        .options-card-container {
            display: flex;
            flex-direction: column;

            .card {
                width: 100%;
                flex: auto;
            }
        }


    }
}

@media screen and (min-width:648px) and (max-width:1025px) {

    .options-container {
        margin: 0px;

        .options-card-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .card {
                width: 100%;
                flex: 1 1 270px;
            }
        }


    }
}