.dropdown-wrapper {

  position: relative;

  .div-wrapper {
    display: flex;
    flex-direction: column;
    transition: height 0.4s ease-in-out;
  
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .div-wrapper:focus-within {
    max-height: 200px;
  }

  ul {
    padding-inline-start: 0px;
    


    li {
      list-style: none;
      cursor: pointer;
      padding: 6px 10px;
      margin:2px 2px;
   
    }

    li:hover {
      background-color: #f2f2f2;
    
    }

    li:focus {
      background-color: #f2f2f2;
  
    }
  }

  .search-dropdown {
    transition: all 0.4s ease-in;
    max-height: 200px;
    position: absolute;
    z-index: 20;
    top: 47px;
    left:-.00025%;
    background-color: #fafafa;
    overflow-y: scroll;

    .selected {
      background-color: #e8e8e8;
    }
  }
  
  .div-wrapper:focus-within >.search-dropdown {
    box-shadow:inset 0px 0px 0px 1px #1175D2;
    border: 1px solid #1175D2;
    z-index:20;
  }

  .upArrow {
    background-image: url(../../UI/Icons/up-arrow-black.svg);
    display: block;
    width: 12px;
    height: 12px;
    padding: 0px 14px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .input-wrapper {
    display: flex;
    flex-direction: row;
  }

  .downArrow {
    background-image: url(../../UI/Icons/down-arrow-black.svg);
    width: 12px;
    height: 12px;
    padding: 24px 12px;
    background-repeat: no-repeat;
    background-position: center;
  }

}

.highlight {
  font-weight: 800;
}


// ::-webkit-scrollbar{
//   clip-path: inset(1px 0px 1px 0px) ;
// }